export class SliderItem{
    public id: string;

    constructor(id: string){
        this.id = id;
    }

    public getImagePath = () => {
        return `images/slider/${this.id}.jpeg`;
    };
}

export const SliderItems = [
    new SliderItem('01'),
    new SliderItem('02'),
    new SliderItem('03'),
    new SliderItem('04'),
    new SliderItem('05'),
    new SliderItem('06'),
    new SliderItem('07'),
    new SliderItem('08'),
    new SliderItem('09')    
]