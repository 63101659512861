import React from 'react';
import { CategoriesData } from '../data/CategoriesData'
import { Route, Link } from "react-router-dom";
import { Category } from './Category';
import { AppContext } from '../data/Context';

export class Categories extends React.Component {
  constructor(props) {
    super(props);
    this.sectionRefs = {
      mainCategories: null,
      mainCategory: null
    };
  }

  render() {
    return (
      <div ref={(section) => { this.sectionRefs.mainCategories = section }}>
        <SectionHeader category={this.props.category} />
        <MainCategoryLinks />
        <MainCategoryRoute sectionRefs={this.sectionRefs} setRef={(section) => { this.sectionRefs.mainCategory = section }} />
      </div>
    );
  }
}

const SectionHeader = (props) => {
  return (
    <div className="text-center">
      <h1><AppContext.Consumer>{context => (context.lang.productsHeader)}</AppContext.Consumer></h1>
      <hr />
    </div>
  );
}

const MainCategoryLinks = (props) => {
  return (
    <div>
      <div className="row">
        {CategoriesData.map((data, index) => (<MainCategoryLink key={index} category={data} />))}
        <div className="col-xs-12 col-sm-4 col-md-4 text-center">
          <a class="category-link" href="https://glasbau-otto.at/" target="_blank">
            <img class="thumbnail img-rounded" src="images/thumbnails/fotos_kategorien/kgb.jpg" alt="" />
          </a>
          <div className="caption text-center">
          <AppContext.Consumer>{context => (<h4>{context.lang.kgb_name}</h4>)}</AppContext.Consumer>
          </div>
        </div>
      </div>
    </div>
  );
}

const MainCategoryLink = (props) => {
  return (
    <div className="col-xs-12 col-sm-4 col-md-4 text-center">
      <Link className="category-link" to={{ pathname: `/${props.category.id}` }}>
        <img className="thumbnail img-rounded" src={props.category.thumbnailPath} alt={props.category.description} />
      </Link>
      <div className="caption text-center">
        <AppContext.Consumer>{context => (<h4>{context.getContent(`${props.category.id}_name`)}</h4>)}</AppContext.Consumer>
      </div>
    </div>
  )
}

const MainCategoryRoute = (props) => {
  return (
    <div className="row" ref={props.setRef}>
      <div className="col-sm-12 col-md-12">
        <Route path='/:cat' component={(p) => new Category(p, props.sectionRefs)} />
      </div>
    </div>
  );
}

