import React from 'react';
import { AppContext } from '../data/Context';
import Fade from 'react-reveal/Fade';

export const Description = (props) => {
  return (
    <div>
      <div className="text-center">
        <AppContext.Consumer>{context => (<h1>{context.lang.descriptionHeader}</h1>)}</AppContext.Consumer>
        <hr />
        <Fade right>
          <div className="col-md-12 col-sm-12 col-xs-12">
          <AppContext.Consumer>{context => (<div dangerouslySetInnerHTML={{ __html: context.lang.descriptionContent }} />)}</AppContext.Consumer>
          </div>
        </Fade>
      </div>
    </div>
  );
}