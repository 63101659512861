import { Category } from './Category';

export const CategoriesData = [
    new Category("kapselstander", "", "", "kapselstander", 9, " images/thumbnails/fotos_kategorien/tapsstander.jpg", []),
    new Category("handguss", "", "", "handguss", 16, "images/thumbnails/fotos_kategorien/handguss.jpg", []),
    new Category("vasenschalen", "", "", "vasenschalen", 20, "images/thumbnails/fotos_kategorien/vasenschalen.jpg", []),
    new Category("lampenlichtobjekte", "", "", "lampenlichtobjekte", 4, "images/thumbnails/fotos_kategorien/lampenlichtobjekte.jpg", []),
    new Category("skulpturen", "", "", "skulpturen", 4, "images/thumbnails/fotos_kategorien/skulpturen.jpg", []),
    new Category("mobel", "", "", "mobel", 10, "images/thumbnails/fotos_kategorien/mobel.jpg", []),
    new Category("bilder", "", "", "bilder", 5, "images/thumbnails/fotos_kategorien/bilder.jpg", []),
    new Category("kugelsicher", "", "", "kugelsicher", 7, "images/thumbnails/fotos_kategorien/kugelsicher.jpg", [])
];

export const getCategoryById = (mainCatId: string, subCatId: string) => {
    const mainCat: Category | undefined = CategoriesData.find((cat: Category) => cat.id === mainCatId);

    if (!subCatId) {
        return mainCat;
    }

    if (mainCat && subCatId) {
        return mainCat.subCategories.find((subCat: Category) => subCat.id === subCatId);
    }

    return null;
};