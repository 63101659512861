export type LangType = {
    de: any,
    en: any
}

export const lang: LangType = {
    de: {
        id: 'de',
        menuHome: 'Home',
        menuDescription: 'Portrait',
        menuProducts: 'Produkte',
        menuContact: 'Kontakt',
        menuHandguss: 'Handguss',
        menuFusing: 'Fusing',

        descriptionHeader: 'Glaskunst by Christopher Otto',
        descriptionContent: `<div>
                                <p class="about-text text-center">
                                    Ein Werkstoff mit grosser Tradition und noch grösserer Zukunft
                                </p>
                                <div class="text-center">
                                    <h1>Portrait</h1>
                                    <hr />
                                </div>
                                <p class="about-text text-center">
                                    Seit seiner Geburt ist Christopher Otto in der Glaserei des elterlichen Betriebes aufgewachsen und stolz, nun die 4. Generation des 1911 gegründeten Betriebes weiterzuführen.
                                </p>
                                <p class="about-text text-center">
                                    Nach abgeschlossener Meisterprüfung im Jahr 2001 widmete er sich neben der Bauverglasung auch der Glaskunst mit all Ihren Techniken und Möglichkeiten.
                                </p>
                                <p class="about-text text-center">
                                    Ob in der Innenarchitektur, bis hin zu Skulpturen und einzigartigen Designobjekten.
                                </p>
                                <p class="about-text text-center">
                                    Jedes seiner Werke bleibt ein Unikat um das Exklusive hervorzuheben und der Massenproduktion zu widersagen.
                                </p>
                                <p class="about-text text-center">
                                    Seine größte Ambition ist es, seinen Kunden, maßgeschneiderte Glaskunst zu fertigen, welche sich in die Umgebung einfügt, oder ganz bewusst Akzente setzt.
                                </p>
                                <p class="about-text text-right">
                                    The glass observer
                                </p>
                            </div>`,
        productsHeader: 'Produkte',
        fusingHeader: 'Fusing',
        fusingContent: `<div>
                            <p class="about-text text-center">
                                Fusing oder Verschmelzung beschreibt eine Handwerkstechnik, bei der man Glas kontrolliert, erhitzt und jeh nach gewünschten Ergebnis auf eine Temperatur von 600 °C (leichtem verformen) bis 1100 °C (gießen) bringt.
                            </p>
                            <p class="about-text text-center">
                                Ob über eine Form oder Model gebogen, mit Werkzeugen das heiße Glas in Form bringen oder verschiedene Gläser verschmelzen  - der Wunsch bestimmt die Technik.
                            </p>
                            <p class="about-text text-center">
                                Viele meiner Objekte sind mehrmals im Ofen und entstehen durch die Kombination von Schmelze und Biegung.
                            </p>
                            <p class="about-text text-center">
                                Wichtig ist die kontrollierte Abkühlung des Glases über eine geregelte Temperaturkurve um Spannungen im fertigen Objekte zu vermeiden.
                            </p>
                            <p class="about-text text-center">
                                Die Dicke des geschmolzenen Objektes bestimmt die Abkühlzeit welche von 12 Stunden bis hin zu einem halben Jahr dauern kann. 
                            </p>
                        </div>`,
        contactTitle: 'Kontakt',
        contactOpenHeader: 'Öffnungszeiten',
        contactOpenHeaderWeek: 'Mo - Fr:	8:00 - 12:00 und 13:00 - 18:00',
        contactOpenHeaderWeekend: 'Sa: 9:00 - 12:00',

        slider_01_title: '',
        slider_01_desc: '',
        slider_02_title: '',
        slider_02_desc: '',
        slider_03_title: '',
        slider_03_desc: '',
        slider_04_title: '',
        slider_04_desc: '',
        slider_05_title: '',
        slider_05_desc: '',
        slider_06_title: '',
        slider_06_desc: '',
        slider_07_title: '',
        slider_07_desc: '',
        slider_08_title: '',
        slider_08_desc: '',
        slider_09_title: '',
        slider_09_desc: '',

        kapselstander_name: 'Kapselständer',
        kapselstander_desc: `<div class="text-center">
                                <h3><b>Die besondere Art der Aufbewahrung Ihrer Kaffeekapseln.</b></h3>
                                <h4>Ob als Bild, freistehender Spender oder auch integriert in Ihre Küchenrückwand.</h4>
                                <h4>In Design und Farbe an Ihre Küche oder Büro angepasst.</h4>
                                <h4>Für alle Kapselmarken möglich.</h4>
                            </div>`,
                            
        handguss_name: 'Handguss',
        handguss_desc: `<div class="center">
                            <p class="about-text text-center">
                            Die Abformung des Handabdruckes ihrer Kinder oder Liebsten und Geschmolzen aus Glas, so fein dass sogar die Fingerabdrücke zu sehen sind. Jedes Stück ein Unikat ganz speziell für den Beschenkten.
                            </p>
                            <p class="about-text text-center">
                            Die Abformung erfolgt in der Kunstwerkstätte der Glaserei Otto und dauert  für gewöhnlich 30 Minuten. Das Abformmaterial ist für Kinder oder Haustiere völlig unbedenklich und lässt sich im Falle eines Fehlversuches beliebig oft ohne Aufpreis wiederholen bis ein gewünschtes Ergebnis entstanden ist.
                            </p>
                            <p class="about-text text-center">
                            Danach wird ein Hochhitzefestes Formpositiv angefertigt, welches nach kompletter Austrocknung in den Schmelzofen kommt.
                            </p>
                            <p class="about-text text-center">
                            Die Glasschmelze geschieht bei ungefähr 950 Grad und bleibt bis zur vollständigen Abkühlung 18 Stunden über eine geregelte Temperaturkurve im Ofen.
                            </p>
                            <p class="about-text text-center">
                            Nach Entnahme des fertigen Abdrucks sind den Gestaltungsmöglichkeiten keine Grenzen gesetzt.
                            </p>
                            <p class="about-text text-center">
                            Eine Passepartoutlackierung, Spiegelhinterlegung, mit einer Rahmenauswahl von über 2000 Rahmen oder als Wand oder Tischlampe. 
                            </p>
                            <h2>
                            Preisbeispiel 
                            </h2>
                            <div class="about-text" style="margin-top:25px;">
                                <ul class="text-left">
                                    <li>Eine Abformung von Hand, Fuß oder Pfote 180 € inkl. 20% Mwst</li>
                                    <li>Das Paket beinhaltet:</li>
                                    <ul>
                                        <li>Anfertigung von Formpositiv</li>
                                        <li>Glasschmelze Abmessung bis 35 x 35 cm</li>
                                        <li>Spiegelhinterlegung (verstärkt den Glanzeffekt)</li>
                                        <li>Einrahmung mit einer speziellen Auswahl von Holzrahmen Bonanza</li>
                                    </ul>
                                    <li>Individuelle Einrahmung nach Wunsch gegen Aufpreis möglich</li>
                                    <li>Lackierung in verschiedenen Farben gegen Aufpreis</li>
                                    <li>Farbspiegel bronze / gold / grau gegen Aufpreis</li>
                                </ul>
                            </div>
                        </div>`,

        bilder_name: 'Bilder',
        bilder_desc: '',

        kugelsicher_name: 'Kugelsicher',
        kugelsicher_desc: '',

        lampenlichtobjekte_name: 'Lampen, Lichtobjekte',
        lampenlichtobjekte_desc: '',

        mobel_name: 'Möbel',
        mobel_desc: '',

        skulpturen_name: 'Skulpturen & Freie Arbeiten',
        skulpturen_desc: '',

        vasenschalen_name: 'Vasen, Schalen',
        vasenschalen_desc: '',

        kgb_name: 'Kunst und Glas am Bau',
    },
    en: {
        id: 'en',
        menuHome: 'Home',
        menuDescription: 'Portrait',
        menuProducts: 'Produkte',
        menuContact: 'Kontakt',
        menuHandguss: 'Handguss',
        menuFusing: 'Fusing',

        descriptionHeader: 'Glaskunst by Christopher Otto',
        descriptionContent: `<div>
                                <p class="about-text text-center">
                                    Ein Werkstoff mit grosser Tradition und noch grösserer Zukunft
                                </p>
                                <div class="text-center">
                                    <h1>Portrait</h1>
                                    <hr />
                                </div>
                                <p class="about-text text-center">
                                    Seit seiner Geburt ist Christopher Otto in der Glaserei des elterlichen Betriebes aufgewachsen und stolz, nun die 4. Generation des 1911 gegründeten Betriebes weiterzuführen.
                                </p>
                                <p class="about-text text-center">
                                    Nach abgeschlossener Meisterprüfung im Jahr 2001 widmete er sich neben der Bauverglasung auch der Glaskunst mit all Ihren Techniken und Möglichkeiten.
                                </p>
                                <p class="about-text text-center">
                                    Ob in der Innenarchitektur, bis hin zu Skulpturen und einzigartigen Designobjekten.
                                </p>
                                <p class="about-text text-center">
                                    Jedes seiner Werke bleibt ein Unikat um das Exklusive hervorzuheben und der Massenproduktion zu widersagen.
                                </p>
                                <p class="about-text text-center">
                                    Seine größte Ambition ist es, seinen Kunden, maßgeschneiderte Glaskunst zu fertigen, welche sich in die Umgebung einfügt, oder ganz bewusst Akzente setzt.
                                </p>
                                <p class="about-text text-right">
                                    The glass observer
                                </p>
                            </div>`,
        productsHeader: 'Produkte',
        fusingHeader: 'Fusing',
        fusingContent: `<div>
                            <p class="about-text text-center">
                                Fusing oder Verschmelzung beschreibt eine Handwerkstechnik, bei der man Glas kontrolliert, erhitzt und jeh nach gewünschten Ergebnis auf eine Temperatur von 600 °C (leichtem verformen) bis 1100 °C (gießen) bringt.
                            </p>
                            <p class="about-text text-center">
                                Ob über eine Form oder Model gebogen, mit Werkzeugen das heiße Glas in Form bringen oder verschiedene Gläser verschmelzen  - der Wunsch bestimmt die Technik.
                            </p>
                            <p class="about-text text-center">
                                Viele meiner Objekte sind mehrmals im Ofen und entstehen durch die Kombination von Schmelze und Biegung.
                            </p>
                            <p class="about-text text-center">
                                Wichtig ist die kontrollierte Abkühlung des Glases über eine geregelte Temperaturkurve um Spannungen im fertigen Objekte zu vermeiden.
                            </p>
                            <p class="about-text text-center">
                                Die Dicke des geschmolzenen Objektes bestimmt die Abkühlzeit welche von 12 Stunden bis hin zu einem halben Jahr dauern kann. 
                            </p>
                        </div>`,
        contactTitle: 'Kontakt',
        contactOpenHeader: 'Öffnungszeiten',
        contactOpenHeaderWeek: 'Mo - Fr:	8:00 - 12:00 und 13:00 - 18:00',
        contactOpenHeaderWeekend: 'Sa: 9:00 - 12:00',

        slider_01_title: '',
        slider_01_desc: '',
        slider_02_title: '',
        slider_02_desc: '',
        slider_03_title: '',
        slider_03_desc: '',
        slider_04_title: '',
        slider_04_desc: '',
        slider_05_title: '',
        slider_05_desc: '',
        slider_06_title: '',
        slider_06_desc: '',
        slider_07_title: '',
        slider_07_desc: '',
        slider_08_title: '',
        slider_08_desc: '',
        slider_09_title: '',
        slider_09_desc: '',

        kapselstander_name: 'Kapselständer',
        kapselstander_desc: `<div class="text-center">
                                <h3><b>Die besondere Art der Aufbewahrung Ihrer Kaffeekapseln.</b></h3>
                                <h4>Ob als Bild, freistehender Spender oder auch integriert in Ihre Küchenrückwand.</h4>
                                <h4>In Design und Farbe an Ihre Küche oder Büro angepasst.</h4>
                                <h4>Für alle Kapselmarken möglich.</h4>
                            </div>`,
        handguss_name: 'Handguss',
        handguss_desc: `<div class="center">
                            <p class="about-text text-center">
                            Die Abformung des Handabdruckes ihrer Kinder oder Liebsten und Geschmolzen aus Glas, so fein dass sogar die Fingerabdrücke zu sehen sind. Jedes Stück ein Unikat ganz speziell für den Beschenkten.
                            </p>
                            <p class="about-text text-center">
                            Die Abformung erfolgt in der Kunstwerkstätte der Glaserei Otto und dauert  für gewöhnlich 30 Minuten. Das Abformmaterial ist für Kinder oder Haustiere völlig unbedenklich und lässt sich im Falle eines Fehlversuches beliebig oft ohne Aufpreis wiederholen bis ein gewünschtes Ergebnis entstanden ist.
                            </p>
                            <p class="about-text text-center">
                            Danach wird ein Hochhitzefestes Formpositiv angefertigt, welches nach kompletter Austrocknung in den Schmelzofen kommt.
                            </p>
                            <p class="about-text text-center">
                            Die Glasschmelze geschieht bei ungefähr 950 Grad und bleibt bis zur vollständigen Abkühlung 18 Stunden über eine geregelte Temperaturkurve im Ofen.
                            </p>
                            <p class="about-text text-center">
                            Nach Entnahme des fertigen Abdrucks sind den Gestaltungsmöglichkeiten keine Grenzen gesetzt.
                            </p>
                            <p class="about-text text-center">
                            Eine Passepartoutlackierung, Spiegelhinterlegung, mit einer Rahmenauswahl von über 2000 Rahmen oder als Wand oder Tischlampe. 
                            </p>
                            <h2>
                            Preisbeispiel 
                            </h2>
                            <div class="about-text" style="margin-top:25px;">
                                <ul class="text-left">
                                    <li>Eine Abformung von Hand, Fuß oder Pfote 180 € inkl. 20% Mwst</li>
                                    <li>Das Paket beinhaltet:</li>
                                    <ul>
                                        <li>Anfertigung von Formpositiv</li>
                                        <li>Glasschmelze Abmessung bis 35 x 35 cm</li>
                                        <li>Spiegelhinterlegung (verstärkt den Glanzeffekt)</li>
                                        <li>Einrahmung mit einer speziellen Auswahl von Holzrahmen Bonanza</li>
                                    </ul>
                                    <li>Individuelle Einrahmung nach Wunsch gegen Aufpreis möglich</li>
                                    <li>Lackierung in verschiedenen Farben gegen Aufpreis</li>
                                    <li>Farbspiegel bronze / gold / grau gegen Aufpreis</li>
                                </ul>
                            </div>
                        </div>`,

        bilder_name: 'Bilder',
        bilder_desc: '',

        kugelsicher_name: 'Kugelsicher',
        kugelsicher_desc: '',

        lampenlichtobjekte_name: 'Lampen, Lichtobjekte',
        lampenlichtobjekte_desc: '',

        mobel_name: 'Möbel',
        mobel_desc: '',

        skulpturen_name: 'Skulpturen & Freie Arbeiten',
        skulpturen_desc: '',

        vasenschalen_name: 'Vasen, Schalen',
        vasenschalen_desc: '',

        kgb_name: 'Kunst und Glas am Bau',
    }
}