import React from 'react';
import { getCategoryById } from '../data/CategoriesData'
import { CategoryGallery } from './CategoryGallery';
import { Route, Link } from "react-router-dom";
import scrollToComponent from 'react-scroll-to-component';
import { AppContext } from '../data/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';

export class Category extends React.Component {
  constructor(props, sectionRefs) {
    super(props);
    this.maincat = this.props.match.params.cat;
    this.subcat = this.props.match.params.subcat;
    this.backUrl = `/`;
    this.sectionRefs = sectionRefs;

    this.category = getCategoryById(this.maincat, this.subcat);
    this.backRef = this.sectionRefs.mainCategories;

    if (this.subcat) {
      this.backUrl = `/${this.maincat}`;
      this.backRef = this.sectionRefs.mainCategory;
    }

    this.ownRef = null;
  }

  onClickBack = () => {
    scrollToComponent(this.backRef, { offset: -50, align: 'top', duration: 200 });
    setTimeout(() => { this.props.history.push(this.backUrl) }, 250);
  };

  onRefSet = (section) => {
    this.ownRef = section;
  };

  componentDidMount = () => {
    if (this.props.location.pathname !== this.props.match.url) {
      return;
    }

    scrollToComponent(this.ownRef, { offset: -50, align: 'top', duration: 200 });
  };

  render() {
    return (
      <div className="text-center" ref={this.onRefSet}>
        <CategoryName category={this.category} />
        <BackButton onClickBack={() => this.onClickBack()} />
        <CategoryDescription category={this.category} />
        {
          this.category.subCategories && this.category.subCategories.length > 0
            ? <SubCategory category={this.category} sectionRefs={this.sectionRefs} />
            : <CategoryGallery category={this.category} />
        }
      </div>
    );
  }
}

const CategoryName = (props) => {
  return (
    <div>
      <AppContext.Consumer>
        {context => (<h1>{context.getContent(`${props.category.id}_name`)}</h1>)}
      </AppContext.Consumer>
      <hr style={{ width: '80%' }} />
    </div>
  );
}

const CategoryDescription = (props) => {
  return (
    <AppContext.Consumer>
      {
        context => {
          return (<div dangerouslySetInnerHTML={{ __html: context.getContent(`${props.category.id}_desc`) }} />)
        }
      }
    </AppContext.Consumer>
  );
}

const SubCategory = (props) => {
  return (
    <div>
      <SubCategoryLinks category={props.category} />
      <SubCategoryRoute sectionRefs={props.sectionRefs} />
    </div>
  );
}

const SubCategoryLinks = (props) => {
  return (
    <div className="row">
      {props.category.subCategories.map((data, index) => (<SubCategoryLink key={index} category={data} mainCatId={props.category.id} />))}
    </div>
  );
}

const SubCategoryLink = (props) => {
  return (
    <div className="col-xs-6 col-sm-2 col-md-2 text-center">
      <Link className="category-link" to={{ pathname: `/${props.mainCatId}/${props.category.id}` }}>
        <img className="thumbnail img-rounded thumbnail-subcat" style={{marginBottom: '0px'}} src={props.category.thumbnailPath} alt={props.category.description} />
        <AppContext.Consumer>
          {context => (<span>{context.getContent(`${props.category.id}_name`)}</span>)}
        </AppContext.Consumer>
      </Link>
    </div>
  )
}

const SubCategoryRoute = (props) => {
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <Route path='/:cat/:subcat' component={(p) => new Category(p, props.sectionRefs)} />
      </div>
    </div>
  );
}

const BackButton = (props) => {
  return (
    <div className="text-center category-back-button">
      <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faChevronCircleUp} size="3x" onClick={() => props.onClickBack()} />
    </div>
  );
}